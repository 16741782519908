import { useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import {
  createWorkflow,
  editWorkflow,
  getMetadata,
  getWorkflow,
  IField,
} from "../../services/assetServices";
import { useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom, successMessageAtom } from "../../atoms/messageBarAtoms";
import OtherInfo from "../../common/OtherInfo";

const Workflow = () => {
  const isSaved = useRef(false);
  const [workflowOtherInfo, setWorkflowOtherInfo] = useState<string>("{}");
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const [entityTypeDateFields, setEntityTypeDateFields] = useState<IField[]>([]);

  const fetchEntityTypeData = async (entityType: string) => {
    const abortController = new AbortController();
    try {
      setIsInProgress(true);
      const data = await getMetadata(abortController, entityType, true);
      const dateFields = data?.fields.filter(f => f.fieldType === "Date") ?? []
      setEntityTypeDateFields(dateFields);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
  };

  return (
    <>
      <div>Workflow</div>
      <EditItem
        getAction={async (abortController, id) => {
          const workflow = await getWorkflow(abortController, id)
          setWorkflowOtherInfo(workflow.otherInfo);
          return workflow;
        }}
        newAction={async (abortController, newWorkflow) => {
          const newWorkflowId = await createWorkflow(abortController, {
            name: newWorkflow.name,
            url: newWorkflow.url,
            trigger: newWorkflow.trigger,
            entityType: newWorkflow.entityType,
            dateField: newWorkflow.dateField,
            beforeOrAfterDateFeild: newWorkflow.dateTimeDiff,
            otherInfo: workflowOtherInfo,
          });
          setSuccessMessage(`Workflow with id: ${newWorkflowId} created.`);
        }}
        editAction={async (abortController, id, editedWorkflow) => {
          await editWorkflow(abortController, id, {
            name: editedWorkflow.name,
            url: editedWorkflow.url,
            trigger: editedWorkflow.trigger,
            entityType: editedWorkflow.entityType,
            dateField: editedWorkflow.dateField,
            beforeOrAfterDateFeild: editedWorkflow.dateTimeDiff,
            otherInfo: workflowOtherInfo,
          });
          setSuccessMessage(`Workflow with id: ${id} successfully updated.`);
        }}
        afterChange={(item) => {
          console.log("afterChange" + JSON.stringify(item));
          if ('triggerType' in item) {
            if (item.triggerType !== 'EntityBased') {
              eval('document.getElementById("field_trigger").style.display = "none"');
              eval('document.getElementById("field_entityType").style.display = "flex"');
              eval('document.getElementById("field_dateField").style.display = "flex"');
              eval('document.getElementById("field_dateTimeDiff").style.display = "flex"');
            } else {
              eval('document.getElementById("field_trigger").style.display = "flex"');
              eval('document.getElementById("field_entityType").style.display = "none"');
              eval('document.getElementById("field_dateField").style.display = "none"');
              eval('document.getElementById("field_dateTimeDiff").style.display = "none"');
            }
          }
          if ('entityType' in item) {
            const entityType = item.entityType;
            fetchEntityTypeData(entityType);
          }
        }}
        isSaved={isSaved}
        back={"/workflows"}
        metadata={{
          fields: [
            {
              name: "name",
              fieldType: "String",
              label: "Name",
            },
            {
              name: "url",
              fieldType: "String",
              label: "Url",
            },
            {
              name: "triggerType",
              fieldType: "Lookup",
              label: "Trigger Type",
              lookupList: "TriggerTypes",
            },
            {
              name: "trigger",
              fieldType: "Lookup",
              label: "Trigger",
              lookupList: "Triggers",
            },
            {
              name: "entityType",
              fieldType: "Lookup",
              label: "Entity Type",
              lookupList: "EntityTypes",
            },
            {
              name: "dateField",
              fieldType: "Lookup",
              label: "Date Field",
              lookupList: "DateFields",
              hiden: true,
            },
            {
              name: "dateTimeDiff",
              fieldType: "NumberWithUnit",
              label: "Date/Time Difference",
              lookupList: "DateTimeDiffs",
              hiden: true,
            },
          ],
          lookups: [
            {
              name: "TriggerTypes",
              values: [
                { key: "EntityBased", value: "Entity based" },
                { key: "DateFieldBased", value: "Date field based" },
              ],
            },
            {
              name: "EntityTypes",
              values: [
                { key: "Asset", value: "Asset" },
                { key: "Issue", value: "Issue" },
                { key: "WorkOrder", value: "WorkOrder" },
                { key: "Renter", value: "Renter" },
                { key: "Rent", value: "Rent" },
                { key: "Contractor", value: "Contractor" },
                { key: "Zone", value: "Zone" },
              ],
            },
            {
              name: "DateFields",
              values:
                entityTypeDateFields.map(f => ({ key: f.name, value: f.label })) ?? []
            },
            {
              name: "DateTimeDiffs",
              values: [
                { key: "bd", value: "Days before" },
                { key: "bh", value: "Hours before" },
                { key: "ad", value: "Days after" },
                { key: "ah", value: "Hours after" },
              ],
            },
            {
              name: "Triggers",
              values: [
                { key: "NoTrigger", value: "" },
                { key: "issue", value: "Issue", header: true },
                { key: "OnIssue", value: "On reporting an issue" },
                { key: "OnIssueUpdate", value: "On issue update" },
                { key: "workOrder", value: "WorkOrder", header: true },
                { key: "OnWorkOrder", value: "On creation of workorder" },
                { key: "OnWorkOrderUpdate", value: "On updating a workorder" },
                { key: "asset", value: "Asset", header: true },
                { key: "OnAsset", value: "On creation of asset" },
                { key: "OnAssetUpdate", value: "On updating an asset" },
                { key: "rent", value: "Rent", header: true },
                { key: "OnRentStart", value: "On starting a rent" },
                { key: "OnRentEnd", value: "On ending a rent" },
              ],
            },
          ],
          validations: [],
        }}
      >
        <OtherInfo
          metadata={{
            fields: [
              {
                name: "authentication",
                fieldType: "Lookup",
                label: "Authentication method",
                lookupList: "AuthenticationMethods",
              },
              {
                name: "userName",
                fieldType: "String",
                label: "Username",
              },
              {
                name: "password",
                fieldType: "Password",
                label: "Password",
              },
            ],
            lookups: [
              {
                name: "AuthenticationMethods",
                values: [{ key: "Basic", value: "Basic Authentication" }],
              },
            ],
            validations: [],
          }}
          otherInfo={workflowOtherInfo ?? "{}"}
          isSaved={isSaved}
          onOtherInfoChanged={(newValue: string) => {
            setWorkflowOtherInfo(JSON.stringify(newValue));
          }}
          hideTitle
        />
      </EditItem>
    </>
  );
};

export default Workflow;
