import { getAuthHeader } from "./authHelper";

export interface INotifMessage {
  t: string; // type
  l: number; // level
  b: string; // body
}

export interface IAssetDetails {
  asset: IAsset;
  issues: IIssue[];
  qrCode: IQrCode;
}

export interface IZoneDetails {
  id: number;
  name: string;
  qrCode: IQrCode;
  assetCategories: IAssetCategory[];
}

export interface supportedIssue {
  name: string;
  id: string;
}
export interface IAsset {
  changeDate?: string;
  code: string;
  createdAt?: string;
  id: number;
  name: string;
  uuid: string;
  category: string;
  categoryId: number;
  zoneId?: number;
  zoneName?: string;
  statusId?: number;
  statusName?: string;
  supportedIssues?: supportedIssue[];
  otherInfo?: string;
  modifiedAt?: string;
  modifiedBy?: number;
}

export interface ITrackingDevice {
  id: number;
  isActive: boolean;
  type: string;
  code: string;
  name: string;
}

export interface IQrCodesTemplate {
  id: number;
  name: string;
  content: string;
  version: string;
  entityType: string;
  isActive: boolean;
}

export interface IFile {
  id: number;
  name: string;
  url: string;
  purpose: string;
  createdAt?: string;
}

export interface IGroupInUser {
  id: number;
  name: string;
}

export interface IUser {
  id: number;
  username: string;
  firstName?: string;
  lastName?: string;
  email: string;
  groups?: IGroupInUser[];
  defaultTenant?: number;
  createdAt?: string;
}

export interface IGroup {
  id: number;
  name: string;
  contractorId?: number;
}

export interface IAction {
  id: number;
  name: string;
}

export interface IPermission {
  id: number;
  name: string;
  actionId: number;
  userId?: number;
  groupId?: number;
}

export interface IUpdatePermission {
  userId?: number;
  groupId?: number;
  actions: number[];
}

export interface IUpdatePermissionsRequest {
  permissions: IUpdatePermission[];
}

export interface IUpdatePermissionsResponse {
  message: string;
}

export interface IQrCode {
  assetId: number;
  createdAt?: string;
  data: string;
  id: number;
}

export interface IImportedAsset {
  code: string;
  id: number;
  ChangedDate: Date;
}

export interface IImportResult {
  importedItems: IImportedAsset[];
  logFilePath: string;
}

export interface IIssue {
  id: number;
  comment: string;
  imageUrl: string;
  images: string[];
  imagesUrls: string[];
  imagesCaption: string[];
  workOrdersCount: number;
  createdAt: string;
  otherInfo: string;
  zoneId: number;
  zoneAssetCat?: number;
  assetId: number;
  issueTypeId?: number;
  uuid: string;
  steps: string;
}

export interface imageAnalysis {
  caption: string;
}

export interface IField {
  name: string;
  fieldType: string;
  label: string;
  tabName?: string;
  lookupList?: string;
  multiSelect?: boolean;
  reference?: string;
  dependees?: string;
  mandatory?: boolean;
  showInGrid?: boolean;
  permissions?: string[];
  rows?: number;
  hiden?: boolean;
}

export interface ILookupValue {
  key: string;
  value: string;
  header?: boolean;
}
export interface ILookup {
  name: string;
  values: ILookupValue[];
}
export interface IValidation {
  field: string;
  rules: string[];
}

export interface IFieldsTab {
  order: number;
  tabName: string;
  caption: string;
}

export interface IMetadata {
  lookups: ILookup[];
  fields: IField[];
  validations: IValidation[];
  tabs?: IFieldsTab[];
}

export interface IStoreQrCodeResponse {
  id: number;
  version: number;
  isActive: boolean;
}
export interface IRestoreQrCodeResponse {
  id: number;
  version: number;
  isActive: boolean;
  content: string;
}
export interface IUpdateMetadataResponse {
  version: number;
}
export interface IUploadFileRequest {
  name: string;
  content: string;
}
export interface IUploadFileResponse {
  url: string;
  id: number;
}
export interface IContractor {
  id: number;
  name: string;
  code: string;
  workOrders: any;
  createdAt: string;
  otherInfo: string;
  groupId?: number;
  workOrdersIds?: number[];
}
export interface IDateTimeRange {
  startTime: Date;
  endTime: Date;
}
export interface ITimeslot extends IDateTimeRange {
  rentId?: number;
  rentCode?: string;
}
export interface IScheduledStatus extends IDateTimeRange{
  statusId: number;
  statusName: string;
}
export interface IGetAssetAvailabilitiesResponse {
  timeslots: ITimeslot[];
  scheduledStatuses: IScheduledStatus[];
}
export interface IRenter {
  id: number;
  name: string;
  code: string;
  otherInfo: string;
}
export interface IRent {
  id: number;
  assetId: number,
  assetName?: string;
  renterId: number,
  renterName?: string,
  trackingDeviceId?: number;
  trackingDeviceName?: string;
  createdAt: string;
  agreedPrice: number,
  agreedPriceUnit: string,
  fromDate: Date,
  toDate: Date,
  statusId: number,
  status: string,
  description: string;
  code: string;
  otherInfo: string;
}
export interface IWorkOrder {
  id: number;
  issueId: number;
  code: string;
  description: string;
  status: string;
  statusId: number;
  statusDescription: string;
  createdAt: string;
  zoneId?: number;
  assetId?: number;
}
export interface IWorkOrderComment {
  id: number;
  content: string;
  createByUserName?: string;
  createdAt?: Date;
  createdAtFormatted?: string;
  modifiedByUserName?: string;
  modifiedAt?: Date;
  modifiedAtFormatted?: string;
}
export interface IEntityLocation {
  id: number;
  time: string;
  latitude: number;
  longitude: number;
}
export interface IZone {
  id?: number;
  code?: string;
  name?: string;
  otherInfo?: string;
  childZones?: IZone[];
  parentId?: number;
  assetCategories?: IAssetCategory[];
}

export interface ICreateTenantRequest {
  name: string;
  description: string;
  uuid: string;
  otherInfo?: string;
  usersIds?: number[];
  groupsIds?: number[];
}

export interface ICreateTenantResponse {
  tenantId: number;
}

export interface ICreateGroupRequest {
  name: string;
  contractorId?: number;
}

export interface ICreateGroupResponse {
  groupId: number;
}

export interface ICreateUserRequest {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  groupsId: number[];
  defaultTenantId: number;
}

export interface ICreateWorkflowRequest {
  name: string;
  trigger?: string;
  entityType?: string;
  dateField?: string;
  beforeOrAfterDateFeild?: string;
  url: string;
  otherInfo?: string;
}

export interface IUpdateWorkflowRequest {
  name: string;
  trigger?: string;
  entityType?: string;
  dateField?: string;
  beforeOrAfterDateFeild?: string;
  url: string;
  otherInfo?: string;
}

export interface ICreateReferenceValue {
  refName: string;
  key: string;
  value: string;
  parentRef: string;
  parentKey: string;
}

export interface ICreateUserResponse {
  userId: number;
}

export interface ICreateReferenceValueResponse {
  referenceValueId: number;
}

export interface ICreateZoneRequest {
  code: string;
  name: string;
  parentId?: number;
  assetCategories?: number[];
  otherInfo?: string;
}

export interface ICreateCategoryRequest {
  code: string;
  name: string;
  parentId?: number;
  issueTypes?: IIsueType[];
}

export interface IChartData {
  values: Map<string, number>;
}

export interface IPagedCollection<itemType> {
  isLastPage: boolean;
  items: itemType[];
  pageCount: number;
  pageNo: number;
  pageSize: number;
  rowsCount: number;
}

export interface IImage {
  imageContent: string;
}

export interface IIssueImage {
  imageContent: string;
  caption: string;
}

export interface ICreateAssetRequest {
  uuid: string;
  code: string;
  name: string;
  categoryId?: number;
  zoneId?: number;
  statusId?: number;
  changedate: string;
  otherInfo?: string;
}

export interface IUpdateAssetRequest {
  code: string | undefined;
  name: string | undefined;
  categoryId?: number;
  zoneId?: number;
  statusId?: number;
  changedate: string | undefined;
  otherInfo?: string;
}
export interface IUpdateContractorRequest {
  name: string;
  code: string;
  workOrdersIds: number[];
  otherInfo: string;
  description?: string;
  groupId?: number;
}

export interface IUpdateRenterRequest {
  name: string;
  code: string;
  otherInfo: string;
}
export interface IUpdateRentRequest {
  assetId: number;
  renterId: number;
  description: string;
  trackingDeviceId?: number;
  agreedPrice?: number,
  agreedPriceUnit?: string,
  fromDate?: Date,
  toDate?: Date,
  statusId?: number,
  status?: string,
  code: string;
  otherInfo: string;
}
export interface IUpdateTenantRequest {
  name: string;
  description: string;
  usersIds?: number[];
  groupsIds?: number[];
  otherInfo?: string;
}

export interface IUpdateGroupRequest {
  name: string;
  contractorId?: number;
}

export interface IUpdateUserRequest {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  groupsId: number[];
  defaultTenantId: number;
}

export interface IUpdateReferenceValue {
  value: string;
  parentRef: string;
  parentKey: string;
}

export interface IUpdateZoneRequest {
  code: string | undefined;
  name: string | undefined;
  parentId: number | undefined;
  assetCategories?: number[];
  otherInfo?: string;
}
export interface IUpdateIssueRequest {
  assetId: number | undefined;
  zoneId?: number;
  issueTypeId?: number;
  comment?: string;
  otherInfo?: string;
}
export interface IUpdateCategoryRequest {
  code: string | undefined;
  name: string | undefined;
  parentId: number | undefined;
  issueTypes?: IIsueType[];
}
export interface ICreateAssetResponse {
  assetId: number;
  qrCodeId: number;
  created: boolean;
  message?: string;
}
export interface ICreateContractorRequest {
  name: string;
  code: string;
  workOrdersIds: number[];
  otherInfo: string;
  assignmentsDescription: string;
  groupId?: number;
}
export interface ICreateRenterRequest {
  name: string;
  code: string;
  otherInfo: string;
}
export interface ICreateRentRequest {
  assetId: number;
  renterId: number;
  trackingDeviceId?: number;
  description?: string;
  agreedPrice?: number,
  agreedPriceUnit?: string,
  fromDate?: Date,
  toDate?: Date,
  statusId?: number,
  status?: string,
  code: string;
  otherInfo: string;
}
export interface IWorkOrderRequest {
  assetId?: number;
  zoneId?: number;
  description?: string;
  zoneAssetCatId?: number;
  issueTypeId?: number;
  otherInfo?: string;
}

export interface IUpdateWorkOrderRequest {
  description?: string;
  otherInfo?: string;
  status?: string;
  statusId?: number;
}

export interface IWorkOrderAssignmentRequest {
  contractorId: number;
  workOrderId: number;
  description: string;
}

export interface ICreateWorkOrderCommentRequest {
  workOrderId: number;
  content?: string;
}

export interface ICreateWorkOrderCommentResponse {
  id: number;
  createdAt: Date;
  createByUserName: string;
}

export interface IUpdateWorkOrderCommentRequest {
  commentId: number;
  content: string;
}

export interface IUpdateWorkOrderCommentResponse {
  commentId: number;
  modifiedByUserName: string;
  modifiedAt: Date;
}

export interface IScheduleActionRequest {
  time: string;
  actionType: number;
  statusId?: number;
  workflowId?: number;
  description: string;
}

export interface ICreateZoneResponse {
  zoneId: number;
}
export interface ICreateContractorResponse {
  contractorId: number;
}
export interface ICreateRenterResponse {
  renterId: number;
}
export interface ICreateRentResponse {
  rentId: number;
}
export interface IWorkOrderAssignmentResponse {
  workOrderAssignmentId: number;
}
export interface IWorkOrderResponse {
  workOrderId: number;
}
export interface ICreateCategoryResponse {
  assetCategoryId: number;
}
export interface IResendWorkOrderResponse {
  workOrderId: number;
}
export interface ILastImport {
  lastImport: {
    lastImportedDateTime: string;
    lastImportedId: string;
    createdAt: string;
  };
  latestImportedAsset: { code: string; changeDate: string };
  latestAssetInSourceDate: string;
  latestAssetInSourceNum: string;
}

export interface IAssetCategories2IssueTypesRequest {
  csvContent: string;
}

export interface IImportZoneRequest {
  csvContent: string;
}

export interface IChat2CommandRequest {
  text: string;
}

export interface IChatCommand {
  action: string;
  entityType: string;
  code?: string;
  name?: string;
  others?: any;
  schemaValidationErrors?: string[];
}

export interface IChat2CommandResponse extends IChatCommand {
  error?: string;
}

export interface IChatQuery {
  id: number;
  request: string;
  favourite: boolean;
}

export interface IAssetCategories2IssueTypesResponse {
  isSuccess: Boolean;
  errors: string[];
}

export interface IImportZonesResponse {
  isSuccess: Boolean;
  errors: string[];
  importedZones: string[];
  logFilePath: string;
}

export interface ISettings {
  clientUrl: string;
}

export interface IWorkOrderStatuses {
  id: number;
  name: string;
  code: string;
  color: string;
  icon: string;
}

export interface IWorkflow {
  id: number;
  name: string;
  purpose: string;
  otherInfo: string;
}

export interface IAssetStatuses {
  id: number;
  name: string;
  code: string;
  color: string;
  icon: string;
}

export interface IScheduledAction {
  uuid: string;
  entityType: string;
  scheduledTime: Date;
  actionStatus: string;
  assetId: number;
  assetStatusId: number;
  workOrderId: number;
  workOrderStatusId: number;
  description: string;
  createdDate: Date;
  id: number;
  createdAt: Date;
}

export interface IIsueType {
  id?: number;
  code: string;
  name: string;
  priority?: string;
}

export interface IReport {
  type: string;
  title: string;
  source: string;
}

export interface IAssetCategory {
  id?: number;
  code: string;
  name: string;
  subCategories?: IAssetCategory[];
  parentId?: number;
  issueTypes?: IIsueType[];
}

export interface ITenant {
  id: number;
  name: string;
  users?: IUser[];
  groups?: IGroup[];
  otherInfo: string;
}

export interface authorizeRequest {
  username: string;
}

export interface authorizeResponse {
  permissions: number[];
  tenants: ITenant[];
  currentTenant: number;
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const internalFetch = async (
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  abortController: AbortController,
  body?: string,
  noJsonResponse?: boolean,
  retry?: number
): Promise<any> => {
  const headers = await getAuthHeader();

  const signal = abortController?.signal;
  const options = {
    method: method,
    headers: headers,
    timeout: 3000,
    body: body,
    signal: signal,
  };

  for (let attempt = 0; attempt < (retry ?? 1); attempt++) {
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return Promise.resolve(response).then(
          (response) => (noJsonResponse ? response : response.json()),
          (reason: any) => {
            const errorMessage: string = reason.errorMessage || "";
            if (errorMessage.includes("AADSTS50058")) {
              throw new Error(
                "Authentication token expired, please sign in again"
              );
            }
          }
        );
      } else {
        if (attempt === (retry ?? 0)) {
          return Promise.reject(response);
        }
        console.log(`response:${response.status} in attempt: ${attempt}`);
        if (response.status === 403) {
          return Promise.reject({ message: "You don't have permission for this action." });
        }
        await sleep(500);
      }
    } catch (error) {
      if (!signal.aborted) {
        if (attempt === (retry ?? 0)) {
          console.log(error);
          return Promise.reject(error);
        }
        console.log(`fail in attempt: ${attempt}`);
        await sleep(500);
      }
    }
  }
};

export const authorize = async (
  abortController: AbortController,
  payload: authorizeRequest
): Promise<authorizeResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/authorize?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController,
    JSON.stringify(payload),
    undefined,
    3
  );
};

export const changeCurrentTenant = async (
  abortController: AbortController,
  username: string,
  tenantId: number
): Promise<IUpdatePermissionsResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/changeTenant?username=${username}&tenantId=${tenantId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController
  );
};

export const getAssets = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IAsset>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/assets?pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""
    }${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const getAssetAvailabilities = async (
  abortController: AbortController,
  assetId: number,
  durationDays?: number,
  durationHalfADays?: number,
  durationHours?: number,
  durationHalfAnHour?: number,
  before?: string,
  after?: string,
): Promise<IGetAssetAvailabilitiesResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/assetAvailabilities?assetId=${assetId
    }${durationDays ? `&durationDays=${durationDays}` : ""
    }${durationHalfADays ? `&durationHalfADays=${durationHalfADays}` : ""
    }${durationHours ? `&durationHours=${durationHours}` : ""
    }${durationHalfAnHour ? `&durationHalfAnHour=${durationHalfAnHour}` : ""
    }${before ? `&before=${before}` : ""
    }${after ? `&after=${after}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getQrCodesTemplates = async (
  abortController: AbortController,
  entityType: string,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IQrCodesTemplate>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/qrCodeTemplates?pageSize=${pageSize}&pageNo=${pageNo}&entityType=${entityType}${orderBy ? `&orderBy=${orderBy}` : ""
    }${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const getFiles = async (
  abortController: AbortController,
  purpose: string,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IFile>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/files?purpose=${purpose}&pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""
    }${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};
export const getAssetAudits = async (
  abortController: AbortController,
  assetId: number,
  pageSize: number,
  pageNo: number
): Promise<IPagedCollection<IAsset>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/assetAudit?assetId=${assetId}&pageSize=${pageSize}&pageNo=${pageNo}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getUsers = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IUser>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/a/users?pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""
    }${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const getGroups = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IGroup>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/a/groups?pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""
    }${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const getUserOrGroupsNames = async (
  abortController: AbortController,
  ids?: string
): Promise<any> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/a/usersOrGroupsNames?ids=${ids}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const getTenants = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<ITenant>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/a/tenants?pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""
    }${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const getPermissions = async (
  abortController: AbortController,
  userId?: number,
  groupId?: number,
  actionId?: number
): Promise<IPermission[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/permissions?${userId
      ? `userId=${userId}`
      : groupId
        ? `groupId=${groupId}`
        : actionId
          ? `actionId=${actionId}`
          : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getReferenceValues = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  refName: string,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IGroup>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/referenceValues?refName=${refName}&pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""
    }${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const getActions = async (
  abortController: AbortController
): Promise<IAction[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/actions?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getTenant = async (
  abortController: AbortController,
  tenantId: number
): Promise<ITenant> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/tenant?tenantId=${tenantId}&includeUsers=true&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getGroup = async (
  abortController: AbortController,
  groupId: number
): Promise<IGroup> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/group?groupId=${groupId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getUser = async (
  abortController: AbortController,
  userId: number
): Promise<IUser> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/user?userId=${userId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const updatePermissions = async (
  abortController: AbortController,
  permissions: IUpdatePermissionsRequest
): Promise<IUpdatePermissionsResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/permissions?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(permissions)
  );
};

export const getChartsData = async (
  abortController: AbortController,
  entityType: string,
  options?: string
): Promise<IChartData> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/chart/${entityType}?options=${options}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrder = async (
  abortController: AbortController,
  workOrderId: number,
): Promise<IWorkOrder> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/workOrder?workOrderId=${workOrderId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrderComments = async (
  abortController: AbortController,
  workOrderId: number,
  pageSize?: number,
  pageNo?: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IWorkOrderComment>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/workOrderComments?workOrderId=${workOrderId}${pageSize ? `&pageSize=${pageSize}` : ""}${pageNo ? `&pageNo=${pageNo
      }` : ""}${orderBy ? `&orderBy=${orderBy}` : ""}${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrderLocations = async (
  abortController: AbortController,
  workOrderId: number,
  pageSize: number,
  pageNo: number
): Promise<IPagedCollection<IEntityLocation>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workOrderLocations?workOrderId=${workOrderId}&pageSize=${pageSize}&pageNo=${pageNo}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getRentLocations = async (
  abortController: AbortController,
  rentId: number,
  pageSize: number,
  pageNo: number
): Promise<IPagedCollection<IEntityLocation>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/rentLocations?rentId=${rentId}&pageSize=${pageSize}&pageNo=${pageNo}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrderComment = async (
  abortController: AbortController,
  commentId: number,
): Promise<IWorkOrderComment> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/workOrderComment?commentId=${commentId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrderAssignment = async (
  abortController: AbortController,
  contractorId: number,
  pageSize: number,
  pageNo: number,
  search?: string
): Promise<IPagedCollection<IWorkOrder>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/workOrderAssignment?contractorId=${contractorId}&pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getIssue = async (
  abortController: AbortController,
  issueId: number
): Promise<IIssue> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/i/issue?issueId=${issueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getIssues = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  search?: string,
  filters?: string
): Promise<IPagedCollection<IIssue>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/i/issues?pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }${filters ? `&filters=${filters}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const analyseIssueImage = async (
  abortController: AbortController,
  issueId: number,
  imageUrl: string,
): Promise<imageAnalysis> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/i/analyseIssueImage?issueId=${issueId}&issueImageUrl=${imageUrl}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const editIssue = async (
  abortController: AbortController,
  issueId: number,
  issue: IUpdateIssueRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/i/issueById?issueId=${issueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(issue)
  );
};

export const getMetadata = async (
  abortController: AbortController,
  entityType?: string,
  allFields?: boolean,
): Promise<IMetadata> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/metadata?entity=${entityType}&allFields=${allFields}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getReferences = async (
  abortController: AbortController,
  entityType?: string
): Promise<string[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/references?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getReferenceValue = async (
  abortController: AbortController,
  refValueId?: number
): Promise<string[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/referenceValue?referenceValueId=${refValueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getAutoCompleteValues = async (
  abortController: AbortController,
  refName: string,
  text: string,
  dependees?: string
): Promise<{ key: string; value: string }[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/autoCompleteValues?refName=${refName}${dependees ? `&dependees=${dependees}` : ""
    }&text=${text}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getDashboardPanelOptions = async (
  abortController: AbortController,
  panelName: string,
): Promise<IChartData> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/dashboardPanelsOptions/${panelName}?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getDashboardPanels = async (
  abortController: AbortController,
  panelName: string,
  options: string,
): Promise<IChartData> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/dashboardPanels/${panelName}?options=${options}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getContractors = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  search?: string
): Promise<IPagedCollection<IContractor>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/contractors?pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getRenters = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  search?: string
): Promise<IPagedCollection<IRenter>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/renters?pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getRents = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  renterId?: number,
  search?: string
): Promise<IPagedCollection<IRent>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/rents?pageSize=${pageSize}&pageNo=${pageNo}${renterId ? `&renterId=${renterId}` : ""}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getContractor = async (
  abortController: AbortController,
  contractorId?: number,
  contractorCode?: string
): Promise<IContractor> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/contractor?${contractorId
      ? `contractorId=${contractorId}`
      : `contractorCode=${contractorCode}`
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getRenter = async (
  abortController: AbortController,
  renterId?: number,
): Promise<IRenter> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/renterById?renterId=${renterId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getRent = async (
  abortController: AbortController,
  rentId?: number,
): Promise<IRent> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/rentById?rentId=${rentId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getActiveTrackingDevices = async (
  abortController: AbortController,
  search?: string
): Promise<ITrackingDevice[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/activeTrackingDevices?${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getZones = async (
  abortController: AbortController,
  search?: string
): Promise<IZone[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/zones?${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const editZone = async (
  abortController: AbortController,
  zoneId: string,
  zone: IUpdateZoneRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/zoneById?zoneId=${zoneId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(zone)
  );
};

export const editTenant = async (
  abortController: AbortController,
  tenantId: number,
  tenant: IUpdateTenantRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/tenant?tenantId=${tenantId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(tenant)
  );
};

export const editGroup = async (
  abortController: AbortController,
  groupId: number,
  group: IUpdateGroupRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/group?groupId=${groupId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(group)
  );
};

export const deleteGroup = async (
  abortController: AbortController,
  groupId: number
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/group?groupId=${groupId}`,
    "DELETE",
    abortController
  );
};

export const editUser = async (
  abortController: AbortController,
  userId: number,
  user: IUpdateUserRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/user?userId=${userId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(user)
  );
};

export const editReferenceValue = async (
  abortController: AbortController,
  referenceValueId: number,
  referenceValue: IUpdateReferenceValue
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/referenceValue?referenceValueId=${referenceValueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(referenceValue)
  );
};

export const deleteRefValue = async (
  abortController: AbortController,
  referenceValueId: number
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/referenceValue?referenceValueId=${referenceValueId}`,
    "DELETE",
    abortController
  );
};


export const deleteUser = async (
  abortController: AbortController,
  userId: number
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/user?userId=${userId}`,
    "DELETE",
    abortController
  );
};

export const createTenant = async (
  abortController: AbortController,
  tenant: ICreateTenantRequest
): Promise<ICreateTenantResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/tenant`,
    "POST",
    abortController,
    JSON.stringify(tenant)
  );
};

export const createGroup = async (
  abortController: AbortController,
  tenant: ICreateGroupRequest
): Promise<ICreateGroupResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/group`,
    "POST",
    abortController,
    JSON.stringify(tenant)
  );
};

export const createUser = async (
  abortController: AbortController,
  tenant: ICreateUserRequest
): Promise<ICreateUserResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/a/user`,
    "POST",
    abortController,
    JSON.stringify(tenant)
  );
};

export const createReferenceValue = async (
  abortController: AbortController,
  referenceValue: ICreateReferenceValue
): Promise<ICreateReferenceValueResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/referenceValue`,
    "POST",
    abortController,
    JSON.stringify(referenceValue)
  );
};

export const createZone = async (
  abortController: AbortController,
  zone: ICreateZoneRequest
): Promise<ICreateZoneResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/zone`,
    "POST",
    abortController,
    JSON.stringify(zone)
  );
};

export const deleteZone = async (
  abortController: AbortController,
  zoneId: string
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/zoneById?zoneId=${zoneId}`,
    "DELETE",
    abortController
  );
};

export const editCategory = async (
  abortController: AbortController,
  categoryId: string,
  category: IUpdateCategoryRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/assetCategory?assetCategoryId=${categoryId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(category)
  );
};

export const createCategory = async (
  abortController: AbortController,
  category: ICreateCategoryRequest
): Promise<ICreateCategoryResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/assetCategory`,
    "POST",
    abortController,
    JSON.stringify(category)
  );
};

export const deleteCategory = async (
  abortController: AbortController,
  categoryId: number
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/assetCategory?assetCategoryId=${categoryId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "DELETE",
    abortController
  );
};

export const getAsset = async (
  abortController: AbortController,
  assetId?: number,
  assetCode?: string
): Promise<IAssetDetails> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/asset?${assetId ? `assetId=${assetId}` : `assetCode=${assetCode}`
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getZoneDetail = async (
  abortController: AbortController,
  zoneId: string
): Promise<IZoneDetails> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/zoneById?zoneId=${zoneId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const editAsset = async (
  abortController: AbortController,
  assetId: number,
  asset: IUpdateAssetRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/assetById?assetId=${assetId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(asset)
  );
};
export const editContractor = async (
  abortController: AbortController,
  contractorId: number,
  contractor: IUpdateContractorRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/contractor?contractorId=${contractorId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(contractor)
  );
};
export const editRenter = async (
  abortController: AbortController,
  renterId: number,
  renter: IUpdateRenterRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/renterById?renterId=${renterId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(renter)
  );
};

export const editRent = async (
  abortController: AbortController,
  renterId: number,
  renter: IUpdateRentRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/rentById?rentId=${renterId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(renter)
  );
};

export const deleteAsset = async (
  abortController: AbortController,
  assetId: number
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/assetById?assetId=${assetId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "DELETE",
    abortController
  );
};

export const getImportAssets = async (
  abortController: AbortController
): Promise<ILastImport> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/importassets`,
    "GET",
    abortController
  );
};

export const createAsset = async (
  abortController: AbortController,
  data: ICreateAssetRequest
): Promise<ICreateAssetResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/asset`,
    "POST",
    abortController,
    JSON.stringify(data)
  );
};

export const createContractor = async (
  abortController: AbortController,
  data: ICreateContractorRequest
): Promise<ICreateContractorResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/contractor`,
    "POST",
    abortController,
    JSON.stringify(data)
  );
};

export const createRenter = async (
  abortController: AbortController,
  data: ICreateRenterRequest
): Promise<ICreateRenterResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/renter`,
    "POST",
    abortController,
    JSON.stringify(data)
  );
};

export const createRent = async (
  abortController: AbortController,
  data: ICreateRentRequest
): Promise<ICreateRentResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/rent`,
    "POST",
    abortController,
    JSON.stringify(data)
  );
};

export const createWorkOrder = async (
  abortController: AbortController,
  issueId: number,
  data: IWorkOrderRequest
): Promise<IWorkOrderResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workOrder?issueId=${issueId}`,
    "POST",
    abortController,
    JSON.stringify(data)
  );
};

export const updateWorkOrder = async (
  abortController: AbortController,
  issueId: number,
  data: IUpdateWorkOrderRequest
): Promise<IWorkOrderResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workOrder?id=${issueId}`,
    "PUT",
    abortController,
    JSON.stringify(data)
  );
};

export const createWorkOrderAssignment = async (
  abortController: AbortController,
  data: IWorkOrderAssignmentRequest
): Promise<IWorkOrderAssignmentResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workOrderAssignment`,
    "POST",
    abortController,
    JSON.stringify(data)
  );
};

export const createWorkOrderComment = async (
  abortController: AbortController,
  data: ICreateWorkOrderCommentRequest
): Promise<ICreateWorkOrderCommentResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workOrderComment`,
    "POST",
    abortController,
    JSON.stringify(data)
  );
};

export const updateWorkOrderComment = async (
  abortController: AbortController,
  data: IUpdateWorkOrderCommentRequest
): Promise<IUpdateWorkOrderCommentResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workOrderComment`,
    "PUT",
    abortController,
    JSON.stringify(data)
  );
};

export const getAssetCategories = async (
  abortController: AbortController
): Promise<IAssetCategory[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/assetCategories`,
    "GET",
    abortController
  );
};

export const getWorkOrderStatuses = async (
  abortController: AbortController
): Promise<IWorkOrderStatuses[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/getWorkOrderStatuses`,
    "GET",
    abortController
  );
};

export const createWorkflow = async (
  abortController: AbortController,
  tenant: ICreateWorkflowRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workflow`,
    "POST",
    abortController,
    JSON.stringify(tenant)
  );
};

export const editWorkflow = async (
  abortController: AbortController,
  userId: number,
  user: IUpdateWorkflowRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workflow?workflowId=${userId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(user)
  );
};

export const getWorkflow = async (
  abortController: AbortController,
  workflowId: number
): Promise<IWorkflow> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workflow?workflowId=${workflowId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkflows = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string,
): Promise<IPagedCollection<IWorkflow>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/workflows?pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const deleteWorkflow = async (
  abortController: AbortController,
  userId: number
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workflow?workflowId=${userId}`,
    "DELETE",
    abortController
  );
};

export const getAssetStatuses = async (
  abortController: AbortController
): Promise<IAssetStatuses[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/getAssetsStatuses`,
    "GET",
    abortController
  );
};

export const getScheduledUpdateAssetStatus = async (
  abortController: AbortController,
  assetId?: number,
  assetCode?: string
): Promise<IScheduledAction[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/scheduledUpdateAssetStatus?${assetId ? `assetId=${assetId}` : `assetCode=${assetCode}`
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const scheduleUpdateAssetStatus = async (
  abortController: AbortController,
  data: IScheduleActionRequest,
  assetId?: number,
  assetCode?: string
): Promise<IWorkOrderAssignmentResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/scheduleUpdateAssetStatus?${assetId ? `assetId=${assetId}` : `assetCode=${assetCode}`
    }`,
    "POST",
    abortController,
    JSON.stringify(data)
  );
};

export const cancelScheduledUpdateAssetStatus = async (
  abortController: AbortController,
  scheduleActionId: number
): Promise<string> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/scheduleUpdateAssetStatus?scheduleActionId=${scheduleActionId}`,
    "DELETE",
    abortController
  );
};

export const importAssets = async (
  abortController: AbortController,
  clearChoice: boolean,
  seed: number,
  csvContent?: string
): Promise<IImportResult> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/importAssets`,
    "POST",
    abortController,
    JSON.stringify({
      reset: clearChoice,
      seed: seed,
      csvContent: csvContent,
    })
  );
};

export const previewImportAssets = async (
  abortController: AbortController
): Promise<IImportedAsset[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/importAssetsPreview`,
    "GET",
    abortController
  );
};

export const getImage = async (
  abortController: AbortController,
  imageUrl: string,
  container?: string
): Promise<IImage> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/image?imageUrl=${imageUrl}${container ? `&container=${container}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getIssueImage = async (
  abortController: AbortController,
  imageUrl: string,
  container?: string
): Promise<IIssueImage> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/i/issueImage?imageUrl=${imageUrl}${container ? `&container=${container}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrders = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number
): Promise<IPagedCollection<IWorkOrder>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workOrders?pageSize=${pageSize}&pageNo=${pageNo}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const searchWorkOrders = async (
  abortController: AbortController,
  search: string
): Promise<IWorkOrder[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/searchWorkOrders?search=${search}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrdersOfIssue = async (
  abortController: AbortController,
  issueId: number
): Promise<IWorkOrder[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workOrdersOfIssue?issueId=${issueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const updateWorkOrderStatus = async (
  abortController: AbortController,
  workOrderCode: string,
  workOrderStatus: string,
  workOrderStatusId: number,
  workOrderDescription: string
) => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/workOrder?workOrderCode=${workOrderCode}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify({
      status: workOrderStatus,
      statusId: workOrderStatusId,
      description: workOrderDescription,
    })
  );
};

export const storeQrCodeTemplate = async (
  abortController: AbortController,
  entityType: string,
  content: string,
  isActive: boolean,
  name?: string
): Promise<IStoreQrCodeResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/qrCodeTemplate?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController,
    JSON.stringify({
      entityType: entityType,
      name: name,
      isActive: isActive,
      content: content,
    })
  );
};

export const deleteQrCodeTemplate = async (
  abortController: AbortController,
  templateId: number
): Promise<IStoreQrCodeResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/qrCodeTemplate?templateId=${templateId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "DELETE",
    abortController
  );
};

export const activeQrCodeTemplate = async (
  abortController: AbortController,
  templateId: number
): Promise<IStoreQrCodeResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/activeQrCodeTemplate?templateId=${templateId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController
  );
};

export const restoreQrCodeTemplate = async (
  abortController: AbortController,
  templateId: number
): Promise<IRestoreQrCodeResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/qrCodeTemplate?templateId=${templateId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const updateMetadata = async (
  abortController: AbortController,
  entityType: string,
  metadata: IMetadata,
  name?: string
): Promise<IUpdateMetadataResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/metadata?entityType=${entityType}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify({
      name: name,
      metadata: metadata,
    })
  );
};

export const uploadFile = async (
  abortController: AbortController,
  name: string,
  content: string
): Promise<IUploadFileResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/file?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController,
    JSON.stringify({
      name: name,
      content: content,
    })
  );
};

export const uploadImage = async (
  abortController: AbortController,
  name: string,
  purpose: string,
  content: ArrayBuffer
): Promise<IUploadFileResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/image?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController,
    JSON.stringify({
      name: name,
      purpose: purpose,
      content: content,
    })
  );
};

export const resendWorkOrder = async (
  abortController: AbortController,
  workOrderId: number
): Promise<IResendWorkOrderResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/resendWorkOrder?workOrderId=${workOrderId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController
  );
};

export enum QrCodeEntityTypes {
  Asset,
  Zone,
}

export const getQrCodes = async (
  abortController: AbortController,
  entity: QrCodeEntityTypes,
  pageSize: number,
  pageNo: number
): Promise<IPagedCollection<IQrCode>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/qrcodes/${entity}/?pageSize=${pageSize}&pageNo=${pageNo}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getQrCodesDoc = async (
  abortController: AbortController,
  entity: QrCodeEntityTypes
): Promise<Response> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/qrcodesdoc/${entity}`,
    "GET",
    abortController,
    undefined,
    true
  );
};

export const getQrCodesPng = async (
  abortController: AbortController,
  entity: QrCodeEntityTypes
): Promise<Response> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/qrcodespng/${entity}`,
    "GET",
    abortController,
    undefined,
    true
  );
};

export const getLogFile = async (
  abortController: AbortController,
  path: string
): Promise<Response> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/file?filePath=${path}`,
    "GET",
    abortController,
    undefined,
    true
  );
};

export const uploadAssetCategories2IssueTypes = async (
  abortController: AbortController,
  request: IAssetCategories2IssueTypesRequest
): Promise<IAssetCategories2IssueTypesResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/importAssetCategories2IssueTypes`,
    "POST",
    abortController,
    JSON.stringify(request)
  );
};

export const uploadZones = async (
  abortController: AbortController,
  request: IImportZoneRequest
): Promise<IImportZonesResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/importZones`,
    "POST",
    abortController,
    JSON.stringify(request)
  );
};

export const getAssetCategoryIssueTypes = async (
  abortController: AbortController,
  assetCategoryId: string
): Promise<IIsueType[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/assetCategoryIssueTypes?assetCategoryId=${assetCategoryId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getAssetIssueTypes = async (
  abortController: AbortController,
  assetId: number
): Promise<IIsueType[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/assetIssueTypes?assetId=${assetId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getReports = async (
  abortController: AbortController
): Promise<IReport[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/reports?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getSettings = async (
  abortController: AbortController
): Promise<ISettings> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/config`,
    "GET",
    abortController
  );
};

export const getBuildNumber = async (
  abortController: AbortController
): Promise<string> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/buildNumber`,
    "GET",
    abortController
  );
};

export const chat2Command = async (
  abortController: AbortController,
  request: IChat2CommandRequest
): Promise<IChat2CommandResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/convertText`,
    "POST",
    abortController,
    JSON.stringify(request)
  );
};

export const getChatQueries = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IChatQuery>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/p/chats?pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""
    }${search ? `&search=${search}` : ""}&code=${process.env.REACT_APP_BACKEND_API_KEY
    }`,
    "GET",
    abortController
  );
};

export const toggleChatQueries = async (
  abortController: AbortController,
  queryId: number
): Promise<boolean> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/p/favChat?queryId=${queryId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController
  );
};
